var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs/internal/Subject';
var QuestionFormComponent = /** @class */ (function () {
    function QuestionFormComponent(fb) {
        this.fb = fb;
        this.remove = new Subject();
    }
    QuestionFormComponent.prototype.ngOnInit = function () { };
    QuestionFormComponent.prototype.toggleAnswer = function (j) {
        var _this = this;
        if (this.answers.value[j].right) {
            this.answers.value.forEach(function (val, i) {
                if (i === j)
                    return;
                _this.answers.at(i).patchValue(__assign({}, val, { right: false }));
            });
        }
    };
    Object.defineProperty(QuestionFormComponent.prototype, "answers", {
        get: function () {
            return this.form.get('answers');
        },
        enumerable: true,
        configurable: true
    });
    QuestionFormComponent.prototype.deleteAnswer = function (i) {
        this.answers.removeAt(i);
    };
    QuestionFormComponent.prototype.addAnswer = function () {
        this.answers.push(this.fb.group({
            right: false,
            text: ['', Validators.required],
        }));
    };
    return QuestionFormComponent;
}());
export { QuestionFormComponent };
