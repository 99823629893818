import { ChangeDetectorRef, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GamesService } from '../api';
var CreateGamePageComponent = /** @class */ (function () {
    function CreateGamePageComponent(fb, api, router, route, cd) {
        this.fb = fb;
        this.api = api;
        this.router = router;
        this.route = route;
        this.cd = cd;
        this.questionIndex = 0;
        this.resultIndex = 0;
        this.deleting = false;
        this.removeIndex = null;
    }
    CreateGamePageComponent.prototype.ngOnInit = function () {
        if (this.route.snapshot.paramMap.get('id')) {
            this.buildUpdateForm();
        }
        else {
            this.buildCreateForm();
        }
    };
    Object.defineProperty(CreateGamePageComponent.prototype, "tabsBlockWidth", {
        get: function () {
            if (this.tabsBlock) {
                return this.tabsBlock._elementRef.nativeElement.clientWidth;
            }
            return 800;
        },
        enumerable: true,
        configurable: true
    });
    CreateGamePageComponent.prototype.buildUpdateForm = function () {
        var _this = this;
        this.gameId = this.route.snapshot.paramMap.get('id');
        this.accessKey = this.route.snapshot.paramMap.get('accessKey');
        this.api.getById(this.gameId).subscribe(function (rs) { return _this._buildGameForm(rs); });
        this.api.getGameStats(this.accessKey, this.gameId).subscribe(function (rs) { return (_this.stats = rs); });
    };
    CreateGamePageComponent.prototype._buildGameForm = function (game) {
        var _this = this;
        this.form = this.fb.group({
            title: [game.title, Validators.required],
            photoUrl: [game.photoUrl, Validators.required],
            description: game.description,
            questions: this.fb.array(game.questions.map(function (q) { return _this.buildQuestion(q); }), Validators.required),
            results: this.fb.array(game.results.map(function (q) { return _this.buildResult(q); }), Validators.required),
        });
        this.cd.detectChanges();
    };
    CreateGamePageComponent.prototype.trackChanges = function () {
        this.form.valueChanges.subscribe(function (rs) { return localStorage.setItem('___saved_form', JSON.stringify(rs)); });
    };
    CreateGamePageComponent.prototype.buildCreateForm = function () {
        if (localStorage.getItem('___saved_form')) {
            var data = JSON.parse(localStorage.getItem('___saved_form'));
            this._buildGameForm(data);
            this.trackChanges();
            return;
        }
        this.form = this.fb.group({
            title: ['', Validators.required],
            photoUrl: ['', Validators.required],
            description: '',
            questions: this.fb.array([this.buildQuestion()], Validators.required),
            results: this.fb.array([this.buildResult()], Validators.required),
        });
        this.trackChanges();
    };
    // http://via.placeholder.com/640x480
    CreateGamePageComponent.prototype.buildQuestion = function (q) {
        var _this = this;
        if (q === void 0) { q = {}; }
        var answers = this.fb.array([
            this.fb.group({
                right: true,
                text: ['', Validators.required],
            }),
            this.fb.group({
                right: false,
                text: ['', Validators.required],
            }),
        ]);
        if (q.answers) {
            answers = this.fb.array(q.answers.map(function (a) {
                return _this.fb.group({
                    right: a.right,
                    text: [a.text, Validators.required],
                });
            }));
        }
        return this.fb.group({
            photoUrl: [q.photoUrl ? q.photoUrl : 'http://via.placeholder.com/640x480', Validators.required],
            answers: answers,
        });
    };
    CreateGamePageComponent.prototype.buildResult = function (r) {
        if (r === void 0) { r = {}; }
        return this.fb.group({
            scoreFrom: [r.scoreFrom !== undefined ? r.scoreFrom : 1, Validators.required],
            scoreTo: [r.scoreTo ? r.scoreTo : 10, Validators.required],
            title: [r.title ? r.title : '', Validators.required],
            photoUrl: [r.photoUrl ? r.photoUrl : 'http://via.placeholder.com/640x480', Validators.required],
            description: r.description,
        });
    };
    CreateGamePageComponent.prototype.selectQuestion = function (index) {
        this.questionIndex = index;
    };
    CreateGamePageComponent.prototype.selectResult = function (index) {
        this.resultIndex = index;
    };
    CreateGamePageComponent.prototype.addQuestion = function () {
        this.questions.push(this.buildQuestion());
        this.selectLastQuestion();
        this.cd.detectChanges();
    };
    CreateGamePageComponent.prototype.selectLastQuestion = function () {
        this.questionIndex = this.questions.length - 1;
    };
    CreateGamePageComponent.prototype.selectLastResult = function () {
        this.resultIndex = this.results.length - 1;
    };
    CreateGamePageComponent.prototype.removeQuestion = function (i) {
        var _this = this;
        this.removeIndex = null;
        this.deleting = true;
        if (this.questions.length === 1) {
            return;
        }
        setTimeout(function () {
            _this.deleting = false;
            if (_this.questions.length > 1) {
                _this.questions.removeAt(i);
                _this.removeIndex = i;
            }
            _this.selectLastQuestion();
            _this.cd.detectChanges();
        }, 300);
    };
    CreateGamePageComponent.prototype.removeResult = function (i) {
        var _this = this;
        this.removeIndex = null;
        this.deleting = true;
        if (this.results.length === 1) {
            return;
        }
        setTimeout(function () {
            _this.deleting = false;
            if (_this.results.length > 1) {
                _this.results.removeAt(i);
                _this.removeIndex = i;
            }
            _this.selectLastResult();
            _this.cd.detectChanges();
        }, 300);
    };
    CreateGamePageComponent.prototype.addResult = function () {
        this.results.push(this.buildResult());
        this.selectLastResult();
        this.cd.detectChanges();
        // this.resultBlock.nativeElement.scrollLeft = this.resultBlock.nativeElement.scrollWidth;
    };
    CreateGamePageComponent.prototype.selectMainPhoto = function (rs) {
        this.form.patchValue({ photoUrl: rs.path });
    };
    CreateGamePageComponent.prototype.drop = function (e) {
        for (var i = 0; i < e.length; i++) {
            this.form.controls['questions']['controls'][i].setValue(e[i].value);
        }
    };
    CreateGamePageComponent.prototype.dropResults = function (e) {
        for (var i = 0; i < e.length; i++) {
            this.form.controls['results']['controls'][i].setValue(e[i].value);
        }
    };
    CreateGamePageComponent.prototype.saveGame = function () {
        var _this = this;
        if (this.foundNoResult()) {
            return;
        }
        if (this.gameId) {
            this.api.updateGame(this.form.value, this.accessKey, this.gameId).subscribe(function (rs) {
                _this.router.navigate(['game', _this.gameId]);
            });
        }
        else {
            this.api.createGame(this.form.value).subscribe(function (rs) {
                _this.router.navigate(['game', rs._id, { accessKey: rs.accessKey }]);
                localStorage.removeItem('___saved_form');
            });
        }
    };
    CreateGamePageComponent.prototype.foundNoResult = function () {
        var qcount = this.form.value.questions.length;
        var results = this.form.value.results;
        var _loop_1 = function (i) {
            var found = results.filter(function (r) { return r.scoreFrom <= i && r.scoreTo > i; });
            if (found.length === 0) {
                alert("\u041D\u0435\u0442 \u0440\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u0430 \u0434\u043B\u044F " + i + " \u043E\u0442\u0432\u0435\u0442\u043E\u0432");
                return { value: true };
            }
        };
        for (var i = 0; i <= qcount; i++) {
            var state_1 = _loop_1(i);
            if (typeof state_1 === "object")
                return state_1.value;
        }
        return false;
    };
    Object.defineProperty(CreateGamePageComponent.prototype, "questions", {
        get: function () {
            return this.form.get('questions');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreateGamePageComponent.prototype, "results", {
        get: function () {
            return this.form.get('results');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreateGamePageComponent.prototype, "mainPhoto", {
        get: function () {
            return this.form.value.photoUrl;
        },
        enumerable: true,
        configurable: true
    });
    return CreateGamePageComponent;
}());
export { CreateGamePageComponent };
