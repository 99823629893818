/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./game-page.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./game-page.component";
import * as i4 from "../api/api/games.service";
import * as i5 from "@angular/router";
var styles_GamePageComponent = [i0.styles];
var RenderType_GamePageComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_GamePageComponent, data: {} });
export { RenderType_GamePageComponent as RenderType_GamePageComponent };
function View_GamePageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["style", "background-color: #68af15"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0421\u0441\u044B\u043B\u043A\u0430 \u0434\u043B\u044F \u0440\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u043D\u0438\u044F"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "textarea", [["name", ""], ["rows", "2"], ["style", "width: 100%"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0421\u0441\u044B\u043B\u043A\u0430 \u0434\u043B\u044F \u043F\u0443\u0431\u043B\u0438\u043A\u0430\u0446\u0438\u0438"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "textarea", [["name", ""], ["rows", "2"], ["style", "width: 100%"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.editLink(); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.gameLink(); _ck(_v, 8, 0, currVal_1); }); }
export function View_GamePageComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { container: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GamePageComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, [[1, 0], ["container", 1]], null, 0, "div", [["class", "teleplay"]], [[1, "data-id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.accessKey; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.id; _ck(_v, 3, 0, currVal_1); }); }
export function View_GamePageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tp-game-page", [], null, null, null, View_GamePageComponent_0, RenderType_GamePageComponent)), i1.ɵdid(1, 4308992, null, 0, i3.GamePageComponent, [i4.GamesService, i5.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GamePageComponentNgFactory = i1.ɵccf("tp-game-page", i3.GamePageComponent, View_GamePageComponent_Host_0, {}, {}, []);
export { GamePageComponentNgFactory as GamePageComponentNgFactory };
