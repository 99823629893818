import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GamesService } from '../api';

@Component({
  selector: 'tp-create-game-page',
  templateUrl: './create-game-page.component.html',
  styleUrls: ['./create-game-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class CreateGamePageComponent implements OnInit {
  form: FormGroup;

  gameId: string;
  accessKey: string;

  stats;
  questionIndex = 0;
  resultIndex = 0;
  deleting = false;
  removeIndex = null;

  @ViewChild('tabsBlock') tabsBlock;
  @ViewChild('questionBlock') questionBlock;
  @ViewChild('resultBlock') resultBlock;

  constructor(
    private fb: FormBuilder,
    private api: GamesService,
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.route.snapshot.paramMap.get('id')) {
      this.buildUpdateForm();
    } else {
      this.buildCreateForm();
    }
  }

  get tabsBlockWidth() {
    if (this.tabsBlock) {
      return this.tabsBlock._elementRef.nativeElement.clientWidth;
    }
    return 800;
  }

  buildUpdateForm() {
    this.gameId = this.route.snapshot.paramMap.get('id');
    this.accessKey = this.route.snapshot.paramMap.get('accessKey');
    this.api.getById(this.gameId).subscribe(rs => this._buildGameForm(rs));
    this.api.getGameStats(this.accessKey, this.gameId).subscribe(rs => (this.stats = rs));
  }

  private _buildGameForm(game: any): any {
    this.form = this.fb.group({
      title: [game.title, Validators.required],
      photoUrl: [game.photoUrl, Validators.required],
      description: game.description,
      questions: this.fb.array(game.questions.map(q => this.buildQuestion(q)), Validators.required),
      results: this.fb.array(game.results.map(q => this.buildResult(q)), Validators.required),
    } as any);
    this.cd.detectChanges();
  }

  trackChanges() {
    this.form.valueChanges.subscribe(rs => localStorage.setItem('___saved_form', JSON.stringify(rs)));
  }

  buildCreateForm() {
    if (localStorage.getItem('___saved_form')) {
      const data = JSON.parse(localStorage.getItem('___saved_form'));
      this._buildGameForm(data);
      this.trackChanges();
      return;
    }
    this.form = this.fb.group({
      title: ['', Validators.required],
      photoUrl: ['', Validators.required],
      description: '',
      questions: this.fb.array([this.buildQuestion()], Validators.required),
      results: this.fb.array([this.buildResult()], Validators.required),
    });
    this.trackChanges();
  }
  // http://via.placeholder.com/640x480

  buildQuestion(q: any = {}) {
    let answers = this.fb.array([
      this.fb.group({
        right: true,
        text: ['', Validators.required],
      }),
      this.fb.group({
        right: false,
        text: ['', Validators.required],
      }),
    ]);
    if (q.answers) {
      answers = this.fb.array(
        q.answers.map(a =>
          this.fb.group({
            right: a.right,
            text: [a.text, Validators.required],
          } as any)
        )
      );
    }
    return this.fb.group({
      photoUrl: [q.photoUrl ? q.photoUrl : 'http://via.placeholder.com/640x480', Validators.required],
      answers,
    } as any);
  }

  buildResult(r: any = {}) {
    return this.fb.group({
      scoreFrom: [r.scoreFrom !== undefined ? r.scoreFrom : 1, Validators.required],
      scoreTo: [r.scoreTo ? r.scoreTo : 10, Validators.required],
      title: [r.title ? r.title : '', Validators.required],
      photoUrl: [r.photoUrl ? r.photoUrl : 'http://via.placeholder.com/640x480', Validators.required],
      description: r.description,
    } as any);
  }

  selectQuestion(index) {
    this.questionIndex = index;
  }

  selectResult(index) {
    this.resultIndex = index;
  }

  addQuestion() {
    this.questions.push(this.buildQuestion());
    this.selectLastQuestion();
    this.cd.detectChanges();
  }

  selectLastQuestion() {
    this.questionIndex = this.questions.length - 1;
  }

  selectLastResult() {
    this.resultIndex = this.results.length - 1;
  }

  removeQuestion(i) {
    this.removeIndex = null;
    this.deleting = true;
    if (this.questions.length === 1) {
      return;
    }
    setTimeout(() => {
      this.deleting = false;
      if (this.questions.length > 1) {
        this.questions.removeAt(i);
        this.removeIndex = i;
      }
      this.selectLastQuestion();
      this.cd.detectChanges();
    }, 300);
  }

  removeResult(i) {
    this.removeIndex = null;
    this.deleting = true;
    if (this.results.length === 1) {
      return;
    }
    setTimeout(() => {
      this.deleting = false;
      if (this.results.length > 1) {
        this.results.removeAt(i);
        this.removeIndex = i;
      }
      this.selectLastResult();
      this.cd.detectChanges();
    }, 300);
  }

  addResult() {
    this.results.push(this.buildResult());
    this.selectLastResult();
    this.cd.detectChanges();
    // this.resultBlock.nativeElement.scrollLeft = this.resultBlock.nativeElement.scrollWidth;
  }

  selectMainPhoto(rs) {
    this.form.patchValue({ photoUrl: rs.path });
  }

  drop(e) {
    for (let i = 0; i < e.length; i++) {
      this.form.controls['questions']['controls'][i].setValue(e[i].value);
    }
  }

  dropResults(e) {
    for (let i = 0; i < e.length; i++) {
      this.form.controls['results']['controls'][i].setValue(e[i].value);
    }
  }

  saveGame() {
    if (this.foundNoResult()) {
      return;
    }
    if (this.gameId) {
      this.api.updateGame(this.form.value, this.accessKey, this.gameId).subscribe(rs => {
        this.router.navigate(['game', this.gameId]);
      });
    } else {
      this.api.createGame(this.form.value).subscribe(rs => {
        this.router.navigate(['game', rs._id, { accessKey: rs.accessKey }]);
        localStorage.removeItem('___saved_form');
      });
    }
  }

  foundNoResult() {
    const qcount = this.form.value.questions.length;
    const results = this.form.value.results as any[];
    for (let i = 0; i <= qcount; i++) {
      const found = results.filter(r => r.scoreFrom <= i && r.scoreTo > i);
      if (found.length === 0) {
        alert(`Нет результата для ${i} ответов`);
        return true;
      }
    }
    return false;
  }

  get questions() {
    return this.form.get('questions') as FormArray;
  }

  get results() {
    return this.form.get('results') as FormArray;
  }

  get mainPhoto() {
    return this.form.value.photoUrl;
  }
}
