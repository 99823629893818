<div [formGroup]="form" *ngIf="form">
  <h3>Результат №{{index+1}}</h3>
  <button mat-icon-button class="delete-result" (click)="remove.next()"  matTooltip="Удалить">
    <mat-icon>clear</mat-icon>
  </button>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <span>
      Правильный ответов
    </span>
    <mat-form-field style="width: 30%" >
      <mat-select placeholder="От" formControlName="scoreFrom">
        <mat-option *ngFor="let item of qcount" [value]="item">
          {{item}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field style="width: 30%">
      <mat-select placeholder="До" formControlName="scoreTo">
        <mat-option *ngFor="let item of qcount" [value]="item">
          {{item}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field appearance="standard" floatLabel="never">
      <input matInput required formControlName="title" autocomplete="off" placeholder="Заголовок результата">
    </mat-form-field>
  </div>

  <div>
    <mat-form-field>
      <textarea matInput placeholder="Описание" formControlName="description"></textarea>
    </mat-form-field>
  </div>

  <div style="text-align: center;">
    <img [src]="form.value.photoUrl" style="width: 100%">
    <br>
    <tp-photo-uploader (uploaded)="form.patchValue({ photoUrl: $event.path })"></tp-photo-uploader>
  </div>
  <div>

  </div>
</div>
