import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateGamePageComponent } from './create-game-page/create-game-page.component';
import { GamePageComponent } from './game-page/game-page.component';
import { ListComponent } from './list/list.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', component: ListComponent },
  { path: 'create', component: CreateGamePageComponent },
  { path: 'game/:id', component: GamePageComponent },
  { path: 'game/:id/edit/:accessKey', component: CreateGamePageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
