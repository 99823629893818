import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import {
  MatBadgeModule,
  MatButtonModule,
  MatCheckboxModule,
  MatFormFieldModule, MatIconModule,
  MatInputModule,
  MatRadioModule, MatSelectModule,
  MatTabsModule, MatTooltipModule,
} from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { ApiModule, Configuration } from './api';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CreateGamePageComponent } from './create-game-page/create-game-page.component';
import { ListBlockComponent } from './create-game-page/list-block/list-block.component';
import { GamePageComponent } from './game-page/game-page.component';
import { ListComponent } from './list/list.component';
import { PhotoUploaderComponent } from './photo-uploader/photo-uploader.component';
import { QuestionFormComponent } from './question-form/question-form.component';
import { ResultFormComponent } from './result-form/result-form.component';

export function apiFactory() {
  return new Configuration({
    basePath: environment.apiPath,
    apiKeys: {},
  });
}

@NgModule({
  declarations: [
    AppComponent,
    ListComponent,
    CreateGamePageComponent,
    GamePageComponent,
    PhotoUploaderComponent,
    QuestionFormComponent,
    ResultFormComponent,
    ListBlockComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatTabsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatRadioModule,
    MatTooltipModule,
    MatIconModule,
    MatCheckboxModule,
    MatSelectModule,
    MatBadgeModule,
    FlexLayoutModule,
    HttpClientModule,
    ApiModule.forRoot(apiFactory),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
