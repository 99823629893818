<div class="fake-block-list" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px"
     *ngIf="listData && listBlock && listBlockWidth">
  <div class="fake-block-item" *ngFor="let item of fakeItemsArr; let i = index">
    <div *ngIf="listData.length === i" class="add-button" (click)="addBlock()">+</div>
  </div>
</div>

<div class="block-list" *ngIf="listData" fxLayout="row" fxLayoutAlign="space-between flex-start" fxLayoutGap="10px">
  <div class="list-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" #listBlock>
    <div class="item-container animated" *ngFor="let q of listData; let i = index"
         [draggable]="true" [attr.data-index]="i" (dragstart)="onDragStart($event)" (dragenter)="onDragEnter($event)" (dragend)="onDragEnd()"
         (click)="selectBlock(i)">
      <div class="fake-block-item"></div>
      <div class="block-item animated zoomIn" [class.active]="i === blockIndex">
        <div class="block-item-img" [style.backgroundImage]="'url(' + q.value.photoUrl + ')'"></div>
        <mat-icon class="delete-item" (click)="removeBlock(i)" [class.disabled]="deleting || listData.length === 1">close</mat-icon>
      </div>
    </div>
    <div style="width: 1px"></div>
  </div>
  <button mat-icon-button color="primary" class="add-btn" (click)="addBlock()"><mat-icon>add</mat-icon></button>
</div>
