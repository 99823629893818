<div fxLayout="row" fxLayoutAlign="space-between start">
  <h1 class="mat-display-1">Создание игры</h1>
  <div>
    <button mat-stroked-button color="primary" [disabled]="form?.invalid" (click)="saveGame()">Сохранить игру</button>
    &nbsp;
    <button mat-button routerLink="/" color="secondary">Отмена</button>
  </div>
</div>
<mat-tab-group [formGroup]="form" *ngIf="form" #tabsBlock>
  <mat-tab label="Стартовая страница">
    <h3>Стартовая страница</h3>

    <div>
      <mat-form-field>
        <input matInput placeholder="Заголовок" required formControlName="title" autocomplete="off">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <textarea matInput placeholder="Описание" formControlName="description"></textarea>
      </mat-form-field>
    </div>

    <div>
      <img [src]="mainPhoto" *ngIf="mainPhoto" style="width: 100%">
      <br>
      <tp-photo-uploader (uploaded)="selectMainPhoto($event)" [text]="mainPhoto ? 'Изменить фото' : 'Выберите фото'"></tp-photo-uploader>
    </div>
    <br>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <span matBadge="{{questions.length}}" matBadgeOverlap="false" matBadgePosition="above after" matBadgeColor="warn">
        Вопросы
      </span>
    </ng-template>

    <tp-list-block [listData]="form.controls['questions'].controls" [blockWidth]="tabsBlockWidth" [remove]="removeIndex"
                   (add)="addQuestion()" (remove)="removeQuestion($event)" (select)="selectQuestion($event)" (dropEnter)="drop($event)">
    </tp-list-block>

    <tp-question-form *ngIf="questions.controls as questions"
      [form]="questions[questionIndex]" [index]="questionIndex" [questionsLength]="questions.length" (remove)="removeQuestion(questionIndex)"></tp-question-form>

  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <span matBadge="{{results.length}}" matBadgeOverlap="false" matBadgePosition="above after" matBadgeColor="warn">
        Результаты
      </span>
    </ng-template>

    <tp-list-block [listData]="results.controls" [blockWidth]="tabsBlockWidth" [remove]="removeIndex"
                   (add)="addResult()" (remove)="removeResult($event)" (select)="selectResult($event)" (dropEnter)="dropResults($event)">
    </tp-list-block>

    <tp-result-form *ngIf="form.controls['results'].controls as results"
                    [form]="results[resultIndex]" [index]="resultIndex" (remove)="removeResult(resultIndex)"></tp-result-form>

  </mat-tab>

  <mat-tab label="Stats" *ngIf="stats">
    <table style="width: 100%">
      <thead>
        <tr>
          <th style="text-align: left">Метрика</th>
          <th style="text-align: left">Всего</th>
          <th style="text-align: left">Уникальных</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Просмотров</td>
          <td>{{stats.view_count}}</td>
          <td>{{stats.view_count_uniq}}</td>
        </tr>
        <tr>
          <td>Начало</td>
          <td>{{stats.start_count}}</td>
          <td>{{stats.start_count_uniq}}</td>
        </tr>
        <tr>
          <td>Закончено</td>
          <td>{{stats.done_count}}</td>
          <td>{{stats.done_count_uniq}}</td>
        </tr>
      </tbody>
    </table>
  </mat-tab>
</mat-tab-group>
<br>
<br>
<br>
<br>
