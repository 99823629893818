<div fxLayout="row" fxLayoutAlign="space-between start">
  <h1 class="mat-display-1">Игры</h1>
  <button routerLink="create" mat-stroked-button color="primary">Создать игру</button>
</div>

<div>
  <div *ngFor="let game of list$|async" fxLayout="row" fxLayoutAlign="space-between start" class="game-item">
    <div class="image">
      <img [src]="game.photoUrl" style="width: 100%">
    </div>
    <div class="desc">
      <a [routerLink]="['game', game._id]">{{game.title}}</a>
      <p>{{game.description}}</p>
    </div>
  </div>
</div>

