<div *ngIf="accessKey" style="background-color: #68af15">
  <h3>Ссылка для редактирования</h3>
  <textarea name=""  rows="2" style="width: 100%">{{editLink()}}</textarea>

  <h3>Ссылка для публикации</h3>
  <textarea name="" rows="2" style="width: 100%">{{gameLink()}}</textarea>
</div>
<div #container class="teleplay" [attr.data-id]="id"></div>
<br>
<br>
<br>
