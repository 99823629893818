import { Component, OnInit, ChangeDetectionStrategy, Input, Output, ViewChild, ElementRef } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';
import { finalize, tap } from 'rxjs/operators';

@Component({
  selector: 'tp-photo-uploader',
  templateUrl: './photo-uploader.component.html',
  styleUrls: ['./photo-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhotoUploaderComponent implements OnInit {
  @Input() accept = 'image/jpeg,image/jpg,image/png,image/pjpeg,image/webp,image/tiff,image/gif';

  @Input() multiple = true;

  @Input() disabled = false;

  @ViewChild('input') inputEl: ElementRef;

  @Output() uploaded = new Subject();

  uploading = false;

  @Input() text = 'Выберите фото';

  constructor() {}

  selectFiles(files: FileList) {
    if (files.length === 0) {
      return;
    }
    this.uploading = true;
    this.xhrUploadRequest('/api/uploads/photo', files[0])
      .pipe(
        tap(rs => this.uploaded.next(rs)),
        finalize(() => (this.uploading = false))
      )
      .subscribe();
  }

  ngOnInit() {}

  xhrUploadRequest(url: string, uploadImage: File): Observable<any> {
    const file = uploadImage;
    const xhr = new XMLHttpRequest();

    const form = new FormData();
    form.append('photo', file, file.name);
    xhr.open('POST', url, true);

    return Observable.create(function(observer) {
      xhr.onload = xhr.onerror = function() {
        if (xhr.status === 201) {
          observer.next(JSON.parse(xhr.response));
          observer.complete();
        } else {
          observer.error(xhr);
        }
      };

      xhr.send(form);

      return function() {
        xhr.abort();
      };
    });
  }
}
