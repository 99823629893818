<div [formGroup]="form" *ngIf="form">
  <h3>Вопрос №{{index+1}}</h3>
  <button mat-icon-button class="delete-question" [disabled]="questionsLength === 1" (click)="remove.next()" matTooltip="Удалить">
    <mat-icon>clear</mat-icon>
  </button>
  <div style="text-align: center;">
    <img [src]="form.value.photoUrl" style="width: 100%">
    <br>
    <tp-photo-uploader (uploaded)="form.patchValue({ photoUrl: $event.path })"></tp-photo-uploader>
  </div>
  <div formArrayName="answers">
    <div *ngFor="let answer of answers.controls; let j = index"
         [formGroupName]="j" fxLayout="row" fxLayoutAlign="space-between center">
      <mat-form-field appearance="standard" floatLabel="never">
        <input matInput required formControlName="text" autocomplete="off" placeholder="Ответ {{j+1}}">
      </mat-form-field>
      <mat-checkbox formControlName="right" style="margin-left: 20px" matTooltip="Верный" (change)="toggleAnswer(j)"></mat-checkbox>
      <button mat-icon-button color="warn" style="margin-left: 20px" (click)="deleteAnswer(j)"><mat-icon>clear</mat-icon></button>
    </div>
    <div (click)="addAnswer()" class="add-answer">
      Добавить ответ
    </div>
  </div>
</div>
