import { Component, OnInit, ChangeDetectionStrategy, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs/internal/Subject';

@Component({
  selector: 'tp-result-form',
  templateUrl: './result-form.component.html',
  styleUrls: ['./result-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResultFormComponent implements OnInit {

  @Input() form: FormGroup;

  @Input() index: number;

  @Output() remove = new Subject()

  constructor() { }

  ngOnInit() {
  }

  get qcount() {
    return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  }

}
