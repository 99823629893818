import { ChangeDetectionStrategy, Component, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs/internal/Subject';

@Component({
  selector: 'tp-question-form',
  templateUrl: './question-form.component.html',
  styleUrls: ['./question-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionFormComponent implements OnInit {
  @Input() form: any;

  @Input() index: number;
  @Input() questionsLength: number;

  @Output() remove = new Subject();

  constructor(private fb: FormBuilder) {}

  ngOnInit() {}

  toggleAnswer(j) {
    if (this.answers.value[j].right) {
      this.answers.value.forEach((val, i) => {
        if (i === j) return;
        this.answers.at(i).patchValue({
          ...val,
          right: false,
        });
      });
    }
  }

  get answers(): FormArray {
    return this.form.get('answers') as FormArray;
  }

  deleteAnswer(i) {
    this.answers.removeAt(i);
  }

  addAnswer() {
    this.answers.push(
      this.fb.group({
        right: false,
        text: ['', Validators.required],
      })
    );
  }
}
