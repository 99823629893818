import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'tp-list-block',
  templateUrl: './list-block.component.html',
  styleUrls: ['./list-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListBlockComponent implements OnInit, OnChanges {

  fakeItemsArr = Array(7).fill({});
  deleting = false;
  blockIndex = 0;
  dragEl: any;
  timer;
  listBlockWidth = true;

  @Input('blockWidth') blockWidth;
  @Input('listData') listData;
  @Input('remove') removeIndex;
  @Output('add') add = new Subject();
  @Output('remove') remove = new Subject();
  @Output('select') select = new Subject();
  @Output('dropEnter') dropEnter = new Subject();
  @ViewChild('listBlock') listBlock;

  constructor(public cd: ChangeDetectorRef) {}

  ngOnInit() {
    setTimeout(() => {
      this.clearAddedBlockAnimation();
    });
    if (this.listBlock) {
      this.listBlockWidth = this.listBlock.nativeElement.clientWidth + 76 < this.blockWidth;
    }
  }

  ngOnChanges() {
    if (this.removeIndex) {
      this.selectLastBlock();
    }
  }

  addBlock() {
    this.add.next();
    this.selectLastBlock();
    this.clearAddedBlockAnimation();
    this.checkListBlockWidth();
  }

  clearAddedBlockAnimation() {
    this.listBlock.nativeElement.scrollLeft = this.listBlock.nativeElement.scrollWidth;
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      for (let i = 0; i < this.listBlock.nativeElement.querySelectorAll('.item-container').length; i++) {
        if (this.listBlock.nativeElement.children[i].querySelector('.block-item')) {
          this.listBlock.nativeElement.children[i].querySelector('.block-item').classList.remove('zoomIn');
        }
      }
    }, 1000);
  }

  removeBlock(i) {
    this.remove.next(i);
    this.deleting = true;
    if (this.listData.length === 1) {
      return;
    }
    this.listBlock.nativeElement.children[i].querySelector('.block-item').classList.add('zoomOut');
    setTimeout(() => {
      this.deleting = false;
      if (this.listData.length > 1) {
      }
      this.selectLastBlock();
      this.checkListBlockWidth();
    }, 300);
  }

  checkListBlockWidth() {
    this.listBlockWidth = this.listBlock.nativeElement.scrollWidth + 50 <= this.blockWidth;
  }

  selectBlock(i) {
    this.select.next(i);
    this.blockIndex = i;
  }

  selectLastBlock() {
    this.blockIndex = this.listData.length - 1;
    this.selectBlock(this.blockIndex);
  }

  onDragStart(e) {
    this.clearAnimation();
    this.dragEl = e.target;
    this.dragEl.classList.add('opacity');
    this.dragEl.classList.add('select');
    const crt = e.target.cloneNode(true);
    crt.style.position = 'absolute';
    crt.style.left = '-1000px';
    crt.style.top = '0px';
    crt.classList.add('select');
    crt.style.opacity = 1;
    const inner = crt.getElementsByClassName('block-item')[0];
    inner.style.transform = 'rotate(-4deg)';
    inner.classList.add('select');
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text', crt);
    this.dragEl.appendChild(crt);
    e.dataTransfer.setDragImage(crt, 55, 35);
  }

  clearAnimation() {
    const animationBlocks = this.listBlock.nativeElement.querySelectorAll('.zoomIn');
    for (let i = 0; i < animationBlocks.length; i++) {
      animationBlocks[i].classList.remove('zoomIn');
    }
  }

  onDragEnter(e) {
    const target = e.currentTarget;
    if (this.isBefore(this.dragEl, target)) {
      target.parentNode.insertBefore(this.dragEl, target); // insert before
    } else {
      target.parentNode.insertBefore(this.dragEl, target.nextSibling); // insert after
    }
  }

  onDragEnd() {
    this.dragEl.classList.remove('opacity');
    this.dragEl.classList.remove('select');
    const dragBlock = document.body.getElementsByClassName('item-container opacity select');
    for (let i = 0; i < dragBlock.length; i++) {
      dragBlock[i].parentElement.removeChild(dragBlock[i]);
    }
  }

  arrayMove(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }

  isBefore(a, b) {
    if (a.getAttribute('data-index') !== b.getAttribute('data-index')) {
      const oldIndex = Number(a.getAttribute('data-index'));
      const newIndex = Number(b.getAttribute('data-index'));
      this.blockIndex = newIndex;
      this.listData = this.arrayMove(this.listData, oldIndex, newIndex);
      this.dropEnter.next(this.listData);
      this.selectBlock(this.blockIndex);
    }
    if (a.parentNode === b.parentNode) {
      for (let cur = a; cur; cur = cur.previousSibling) {
        if (cur === b) {
          return true;
        }
      }
    }
    return false;
  }

}
