import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GamesService } from '../api';

@Component({
  selector: 'tp-game-page',
  templateUrl: './game-page.component.html',
  styleUrls: ['./game-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class GamePageComponent implements OnInit, AfterViewInit {

  game: any;

  id = this.route.snapshot.paramMap.get('id');

  accessKey = this.route.snapshot.paramMap.get('accessKey');

  @ViewChild('container') container: ElementRef;

  constructor(private api: GamesService, private route: ActivatedRoute) { }

  ngOnInit() {

  }

  gameLink() {
    return `${location.origin}/game/${this.id}`
  }

  editLink() {
    return `${location.origin}/game/${this.id}/edit/${this.accessKey}`
  }

  ngAfterViewInit() {
    (window as any).___TP_ORIGIN = location.origin;
    (window as any).___tp__showGame(this.container.nativeElement)
  }
}
