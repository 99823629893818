import { OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs/internal/Subject';
var ResultFormComponent = /** @class */ (function () {
    function ResultFormComponent() {
        this.remove = new Subject();
    }
    ResultFormComponent.prototype.ngOnInit = function () {
    };
    Object.defineProperty(ResultFormComponent.prototype, "qcount", {
        get: function () {
            return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
        },
        enumerable: true,
        configurable: true
    });
    return ResultFormComponent;
}());
export { ResultFormComponent };
